import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';

import FormWrapper from '../../FormWrapper.js';
import BottomNavigation from '../../BottomNavigation.js';
import InvitationStepper from '../../InvitationStepper.js';

import ConsentVvrb from './ConsentVvrb.js';

export default function ApplicationReviewStep({
  contract_details,
  goToNextStep,
  saveAndGoBack,
  bottom_navigation_label,
  user_consents,
  tenant_display_name,
  tenant_index = null,
}) {
  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => goToNextStep({fields, setError}));
  const onBack = useCallback(
    () => saveAndGoBack({fields: getValues()}),
    [saveAndGoBack, getValues],
  );

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  return (
    <FormWrapper tenant_display_name={tenant_display_name}>
      <ConsentVvrb
        control={control}
        errors={errors}
        setValue={setValue}
        user_consents={user_consents}
      />

      <BottomNavigation
        onSubmit={onSubmit}
        onBack={onBack}
        label={bottom_navigation_label}
      />

      {tenant_index !== null && <InvitationStepper step={tenant_index + 1} />}
    </FormWrapper>
  );
}
