import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      draft.language = action.payload.code;
      break;
    }
    default:
  }
}, {});
