import React, {useState, useEffect, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import UaParser from 'ua-parser-js';
import {_} from 'shared/l10n.js';

import FormWrapper from '../../FormWrapper.js';
import BottomNavigation from '../../BottomNavigation.js';
import {isUploading} from '../../../lib/useFileUpload.js';
import InvitationStepper from '../../InvitationStepper.js';
import FormHeader from '../../FormHeader.js';

import Incomes from './Incomes.js';
import IncomeProofMissingModal from './IncomeProofMissingModal.js';

export default function CurrentIncomeStep({
  contract_details,
  goToNextStep,
  saveAndGoBack,
  bottom_navigation_label,
  tenant_display_name,
  tenant_index = null,
}) {
  const [
    modal_income_proof_missing_visible,
    setModalIncomeProofMissingVisible,
  ] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const {provider} = contract_details;

  const {
    control,
    reset,
    formState: {errors},
    watch,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      incomes: [],
    },
  });

  // We do not use handleSubmit because it refuses to run a second time
  // in case the first time has set the incomes[idx].proofs error message
  // because incomes[idx].proofs is not a registered field
  const onSubmit = useCallback(() => {
    clearErrors();
    return goToNextStep({
      fields: getValues(),
      setError,
      setIsLoading,
    });
  }, [goToNextStep, getValues, setError, clearErrors]);

  useEffect(() => {
    if (!contract_details.incomes?.length) {
      contract_details.incomes = [{cents: '', proofs: []}];
    }

    reset({...contract_details});
  }, [contract_details, reset]);

  useEffect(() => {
    if (errors.incomes && errors.incomes[0]?.proofs && isMobile()) {
      setModalIncomeProofMissingVisible(true);
    }
  }, [errors.incomes]);

  const incomes = watch('incomes');
  const is_uploading = isUploading(...incomes.map((income) => income.proofs));

  return (
    <>
      <FormWrapper tenant_display_name={tenant_display_name}>
        <FormHeader
          title={_('Aktuelles Einkommen')}
          subtitle={_(
            'Diese Angaben helfen uns dabei, Ihre finanzielle Situation einzuschätzen und zu ermitteln, ob wir Ihnen unseren Kautionsschutz anbieten können.',
          )}
        />

        <Incomes
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
          provider={provider}
        />

        <BottomNavigation
          is_loading={is_loading || is_uploading}
          onSubmit={onSubmit}
          onBack={saveAndGoBack}
          label={bottom_navigation_label}
        />

        {tenant_index !== null && <InvitationStepper step={tenant_index + 1} />}
      </FormWrapper>

      <IncomeProofMissingModal
        show={modal_income_proof_missing_visible}
        onClose={() => setModalIncomeProofMissingVisible(false)}
      />
    </>
  );
}

function isMobile() {
  const ua_parsed = new UaParser(navigator.userAgent);
  const device = ua_parsed.getDevice().type;
  if (!device || device === 'console') {
    return false;
  }
  return true;
}
