import {ReactComponent as AddIcon} from 'shared/components/add_circle_primary.svg';
import Button from 'shared/components/Button.js';
import IconButton from 'shared/components/IconButton.js';
import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';

import {ReactComponent as TrashSvg} from '../../../assets/trash.svg';
import SelectGender from '../../../components/SelectGender.js';

import styles from './CoownerFormSection.module.scss';

export default function CoownerFormSection({
  errors,
  register,
  watch,
  setValue,
}) {
  const has_coowner = watch('property_owner_has_coowner');

  if (!has_coowner) {
    return (
      <div className={styles.coowner_button}>
        <Button
          onClick={() => setValue('property_owner_has_coowner', true)}
          title={_('Zweite Person hinzufügen')}
          className={styles.add_second_person_button}
          Icon={AddIcon}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.separator} />
      <div className={styles.header}>
        <p className={text_styles.caption_left}>{_('Zweite Person')}</p>
        <IconButton
          onClick={() => {
            setValue('property_owner_has_coowner', false, {shouldDirty: true});
          }}>
          <TrashSvg />
        </IconButton>
      </div>
      <div>
        <Input
          label={_('Vorname')}
          error={errors.property_owner_coowner_first_name?.message}
          className={styles.margin_right}
          {...register('property_owner_coowner_first_name')}
        />

        <Input
          label={_('Nachname')}
          error={errors.property_owner_coowner_last_name?.message}
          {...register('property_owner_coowner_last_name')}
        />

        <SelectGender
          className={styles.margin_bottom}
          error={errors.property_owner_coowner_gender?.message}
          {...register('property_owner_coowner_gender')}
        />
      </div>
    </>
  );
}
