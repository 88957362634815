import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {_} from 'shared/l10n.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';

import Input from '../../../components/Input.js';
import FormWrapper from '../../../components/FormWrapper.js';
import BottomNavigation from '../../../components/BottomNavigation.js';
import InvitationStepper from '../../../components/InvitationStepper.js';
import FormHeader from '../../../components/FormHeader.js';
import {isDevFormEnabled} from '../../../lib/utils.js';

export default function BasicInformationScreen2() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {tenants, has_second_tenant, tenant_display_name} = contract_details;
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({token, fields, history, setError}),
  );

  async function saveAndGoBack() {
    const fields = getValues();
    tenants[1] = {...tenants[1], ...fields};
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.BasicInformationScreen2,
      contract_details,
    });
    history.push(previous_step, {contract_details, token});
  }

  useEffect(() => {
    reset({...tenants[1]});
  }, [tenants, reset]);

  return (
    <FormWrapper tenant_display_name={_('Mieter 2')}>
      <FormHeader
        title={_('Herzlich Willkommen')}
        subtitle={_(
          'Um gemeinsam mit %1 die Getmomo Kautionsbürgschaft nutzen zu können, benötigen wir ein paar Informationen von Ihnen.',
          tenant_display_name,
        )}
      />

      <form>
        <Input
          defaultValue={''}
          type="text"
          label={_('Vorname')}
          placeholder={'...'}
          error={errors.first_name?.message}
          {...register('first_name')}
        />

        <Input
          defaultValue={''}
          type="text"
          label={_('Nachname')}
          placeholder={'...'}
          error={errors.last_name?.message}
          {...register('last_name')}
        />
      </form>
      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />
      {has_second_tenant && <InvitationStepper step={2} />}
    </FormWrapper>
  );
}

async function proceed({token, fields, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.basic_information_2,
      tenant_index: 1,
      token,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
