import React from 'react';

import styles from './Header.module.scss';
import {ReactComponent as MomoLogo} from './momo_logo.svg';

export default function Header() {
  return (
    <header className={styles.container}>
      <div className={styles.hands} />
      <MomoLogo alt="momo logo" className={styles.logo} />
    </header>
  );
}
