import React from 'react';
import {_} from 'shared/l10n.js';
import classNames from 'classnames/bind.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './Error.module.scss';

export default function DataTransmittedScreen() {
  return (
    <Layout>
      <Title label={_('Die Daten wurden bereits übermittelt')} />
      <p className={text_styles.body1_bright_centered}>
        {_(
          'Bitte wende Sie sich an uns wenn Sie Ihre Angaben noch mal ändern möchten.',
        )}
      </p>

      <a
        href={`mailto:${_('kontakt@getmomo.de')}`}
        rel="noreferrer"
        target="_blank">
        <button
          type="button"
          className={classNames(text_styles.button_bold_dark, styles.button)}>
          {_('GETMOMO kontaktieren')}
        </button>
      </a>
    </Layout>
  );
}
