import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {ReactComponent as FlagDE} from 'shared/node_modules/country-flag-icons/3x2/DE.svg';
import {ReactComponent as FlagGB} from 'shared/node_modules/country-flag-icons/3x2/GB.svg';
import Icon from 'shared/components/Icon.js';

import {ReactComponent as ArrowDownSvg} from './arrow_down.svg';
import styles from './LanguageSelector.module.scss';

export default function LanguageSelector() {
  const {language} = useSelector((state) => state.languageSelector);
  const dispatch = useDispatch();

  function onLanguageChange(code) {
    dispatch({
      type: 'SET_LANGUAGE',
      payload: {
        code,
      },
    });
  }

  return (
    <div className={styles.container}>
      <Dropdown>
        <Dropdown.Toggle as="button">
          <div className={styles.toggle}>
            <Icon>
              <ArrowDownSvg />
            </Icon>

            {language === 'en' ? (
              <Icon>
                <FlagGB />
              </Icon>
            ) : (
              <Icon>
                <FlagDE />
              </Icon>
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="end" className={styles.menu}>
          <Dropdown.Item
            onClick={() => onLanguageChange('de')}
            className={classNames(
              styles.menu_top,
              language === 'de' && styles.dark_background,
            )}>
            <Icon>
              <FlagDE />
            </Icon>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => onLanguageChange('en')}
            className={classNames(
              styles.menu_bottom,
              language === 'en' && styles.dark_background,
            )}>
            <Icon>
              <FlagGB />
            </Icon>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
