import React, {forwardRef} from 'react';
import classNames from 'classnames';
import TextInput from 'shared/components/TextInput.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './Input.module.scss';

function Input(
  {label, placeholder = '...', inputClassName, className, error = '', ...props},
  ref,
) {
  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <div className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </div>
      )}
      <TextInput
        ref={ref}
        className={inputClassName}
        placeholder={placeholder}
        {...props}
      />
      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}

export default forwardRef(Input);
