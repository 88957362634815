import countries_de from 'world_countries_lists/data/countries/de/countries.json';
import countries_en from 'world_countries_lists/data/countries/en/countries.json';
import {l10n} from 'shared/l10n.js';

export function getNationalityOptions() {
  return (l10n.getLocale() === 'de' ? countries_de : countries_en).map(
    (country) => {
      return {
        id: country.alpha2.toUpperCase(),
        name: country.name,
      };
    },
  );
}

export function isProduction() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo.app/tenant/';
}

export function isSandbox() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo-sandbox.app/tenant/';
}

export function isStaging() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'https://api.getmomo-staging.app/tenant/';
}

export function isDevelopment() {
  const {REACT_APP_API_URL} = process.env;
  return REACT_APP_API_URL === 'http://localhost:20305/tenant/';
}

export function isTest() {
  const {NODE_ENV} = process.env;
  return NODE_ENV === 'test';
}

export function getSecondTenantDisplayName(contract_details) {
  return `${contract_details.tenants[1].first_name} ${contract_details.tenants[1].last_name}`;
}

export function isDevFormEnabled() {
  return process.env.REACT_APP_DEV_MODE === 'true';
}
