import CircleSpinner from 'shared/components/CircleSpinner';
import classNames from 'classnames';

import styles from './Thumbnail.module.scss';
import {ReactComponent as PDFSvg} from './pdf_icon.svg';

export default function Thumbnail({document, onClick}) {
  const {filename, loading, url} = document;
  const is_pdf = filename.endsWith('.pdf');

  const className = classNames(styles.image, loading && styles.blur_image);

  return (
    <div className={styles.thumbnail_container} onClick={onClick}>
      {is_pdf ? (
        <PDFSvg className={className} />
      ) : (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img src={url} className={className} height="100%" width="100%" />
      )}
      {loading && (
        <div className={styles.spinner_wrapper}>
          <CircleSpinner
            className={styles.spinner}
            classNameOutline={styles.spinner_color}
            classNameSpinner={styles.spinner_color}
          />
        </div>
      )}
    </div>
  );
}
