import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {_} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';

import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import Input from '../../../components/Input.js';
import FormHeader from '../../../components/FormHeader.js';
import {isDevFormEnabled} from '../../../lib/utils.js';

export default function BasicInformationScreen() {
  const {contract_details, token} = useLocation().state || '';
  const history = useHistory();
  const {tenants} = contract_details;

  const {first_name, last_name} = tenants[0];

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({
      token,
      fields,
      history,
      setError,
    });
  });

  return (
    <FormWrapper>
      <FormHeader
        title={_('Mieter')}
        subtitle={_(
          'Auf welchen Namen soll die Bürgschafturkunde ausgestellt werden?',
        )}
      />
      <Input
        label={_('Ihr Vorname')}
        defaultValue={first_name}
        error={errors.first_name?.message}
        {...register('first_name')}
      />
      <Input
        label={_('Ihr Nachname')}
        defaultValue={last_name}
        error={errors.last_name?.message}
        {...register('last_name')}
      />
      <BottomNavigation onSubmit={onSubmit} />

      <p className={classNames(text_styles.caption_left)}>
        {_(
          '* Falls es mehr als einen Mieter gibt, können Sie später eine weitere Person hinzufügen.',
        )}
      </p>
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitBasicInformation', {
      token,
      tenant_index: 0,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}
