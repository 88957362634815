import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {_} from 'shared/l10n.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';
import DateInput from 'shared/components/DateInput.js';

import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import {isDevFormEnabled} from '../../../lib/utils.js';
import FormHeader from '../../../components/FormHeader.js';

export default function RentalContractScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const [is_submitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: {errors},
    reset,
    control,
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  const onSubmit = handleSubmit((fields) => {
    proceed({token, fields, history, setError, setIsSubmitting});
  });

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.RentalContractScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  return (
    <FormWrapper>
      <FormHeader
        title={_('Einzug & Unterschrift')}
        subtitle={_(
          'Wir benötigen diese Angaben zur Ermittlung des Starttermins Ihres Schutzes. Die Gebühr wird frühestens ab Einzug berechnet.',
        )}
      />

      <Controller
        name="rental_contract_start_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label={_('Der im Mietvertrag vereinbarte Einzugstermin')}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <Controller
        name="signed_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label={_('Wann wurde der Mietvertrag unterschrieben')}
            maxDate={new Date()}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <BottomNavigation
        onSubmit={onSubmit}
        onBack={saveAndGoBack}
        is_loading={is_submitting}
      />
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError, setIsSubmitting}) {
  setIsSubmitting(true);
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.rental_contract,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  } finally {
    setIsSubmitting(false);
  }

  history.push(next_step, {contract_details, token});
}
