import {useReducer} from 'react';
import {_} from 'shared/l10n.js';
import {RPC} from 'shared/api.js';
import produce from 'immer';
import {uploadToMinio} from 'shared/utils.js';

import {alert, confirm, reportError} from '../effects.js';

const ABORT_REASON_USER_REQUEST = Symbol('ABORT_REASON_USER_REQUEST');

export function useSingleFileUpload(document) {
  const {documents, remove, uploadFiles, dispatch} = useMultiFileUpload(
    document ? [document] : [],
  );

  async function uploadFile(file) {
    if (documents[0]) {
      deleteDocument({document: documents[0], dispatch});
    }
    uploadFiles([file]);
  }

  return {document: documents[0], remove, uploadFile};
}

export function useMultiFileUpload(documents) {
  const [state, dispatch] = useReducer(documentUploadReducer, documents || []);

  function remove(document) {
    removeDocument({document, dispatch});
  }

  function uploadFiles(files) {
    dispatch({type: 'FILES_SELECTED', payload: files});
    for (const file of files) {
      uploadFile({file, dispatch});
    }
  }

  return {remove, uploadFiles, documents: state, dispatch};
}

export function isUploading(...documents) {
  return documents.flat().some((document) => document?.loading);
}

const documentUploadReducer = produce((draft, {type, file, payload}) => {
  let document, index;

  switch (type) {
    case 'FILES_SELECTED': {
      draft.push(
        ...payload.map((file) => {
          return {
            file,
            id: null,
            filename: file.name,
            url: URL.createObjectURL(file),
          };
        }),
      );
      break;
    }
    case 'UPLOAD_INIT': {
      document = draft.find((document) => document.file === file);
      if (!document) return;
      Object.assign(document, {loading: true, ...payload});
      break;
    }
    case 'UPLOAD_STARTING': {
      document = draft.find((document) => document.file === file);
      if (!document) return;
      Object.assign(document, {loading: true, ...payload});
      break;
    }
    case 'UPLOAD_COMPLETE': {
      document = draft.find((document) => document.file === file);
      if (!document) return;
      URL.revokeObjectURL(document.url);
      Object.assign(document, {loading: false, ...payload});
      break;
    }
    case 'UPLOAD_CANCELLED':
    case 'UPLOAD_FAILED': {
      index = draft.findIndex((document) => document.file === file);
      if (index < 0) return;
      document = draft[index];
      Object.assign(document, {loading: false});
      URL.revokeObjectURL(document.url);
      draft.splice(index, 1);
      break;
    }
    case 'DELETION_STARTING': {
      index = draft.findIndex((document) => document.id === payload.id);
      if (index < 0) return;
      document = draft[index];
      Object.assign(document, {loading: true});
      break;
    }
    case 'DELETION_COMPLETE': {
      index = draft.findIndex((document) => document.id === payload.id);
      if (index < 0) return;
      document = draft[index];
      Object.assign(document, {loading: false});
      URL.revokeObjectURL(document.url);
      draft.splice(index, 1);
      break;
    }
    default: {
      throw new Error(`Unown document upload reducer action ${type}`);
    }
  }
});

async function confirmDelete({document, dispatch}) {
  if (
    !(await confirm({
      title: _('Möchten Sie diese Datei entfernen?'),
      text: _('Die Aktion kann nicht rückgängig gemacht werden.'),
      proceed_label: _('Jetzt löschen'),
    }))
  ) {
    return;
  }

  await deleteDocument({document, dispatch});
}

async function deleteDocument({document, dispatch}) {
  dispatch({
    type: 'DELETION_STARTING',
    payload: document,
  });

  try {
    await RPC('deleteDocument', {
      id: document.id,
    });
  } catch (err) {
    reportError(err);
  }

  dispatch({type: 'DELETION_COMPLETE', payload: document});
}

async function confirmCancel({document, dispatch}) {
  if (
    !(await confirm({
      title: _('Möchten Sie den Upload abbrechen?'),
      text: _(
        'Der Upload ist noch nicht abgeschlossen. Wenn Sie den Vorgang jetzt abbrechen, werden die Dateien gelöscht.',
      ),
      proceed_label: _('Jetzt löschen'),
    }))
  ) {
    return;
  }

  document.abort_controller?.abort(ABORT_REASON_USER_REQUEST);
  if (document.id) {
    await deleteDocument({document, dispatch});
  }
}

async function removeDocument({document, dispatch}) {
  if (document.abort_controller) {
    await confirmCancel({document, dispatch});
  } else if (document.id) {
    await confirmDelete({document, dispatch});
  }
}

async function uploadFile({file, dispatch}) {
  let result;
  const abort_controller = new AbortController();
  const {signal} = abort_controller;

  function onError(err) {
    if (signal.aborted && signal.reason === ABORT_REASON_USER_REQUEST) {
      dispatch({type: 'UPLOAD_CANCELLED', file});
      return;
    }

    reportError(err);
    alert({
      title: _('Beim Hochladen Ihres Dokumentes gab es ein Problem'),
      text: _(
        'Bitte versuchen Sie es erneut. Sollten Sie weiterhin Probleme mit dem Hochladen haben, wenden Sie sich bitte an den Kundeservice.',
      ),
      email: 'kundenservice@getmomo.de',
    });
    dispatch({type: 'UPLOAD_FAILED', file});
  }

  dispatch({
    type: 'UPLOAD_INIT',
    file,
    payload: {abort_controller},
  });

  try {
    result = await RPC(
      'getUploadUrl',
      {
        filename: file.name,
      },
      {signal},
    );
  } catch (err) {
    return onError(err);
  }

  const {id, filename, upload_url, url} = result;
  dispatch({
    type: 'UPLOAD_STARTING',
    file,
    payload: {id, filename},
  });

  try {
    await uploadToMinio(file, upload_url, signal);
  } catch (err) {
    return onError(err);
  }

  dispatch({
    type: 'UPLOAD_COMPLETE',
    file,
    payload: {url, abort_controller: null},
  });
}
