import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames/bind';
import {RPC, NOT_FOUND} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import {_} from 'shared/l10n.js';
import {redirectExternal} from 'shared/utils.js';
import {TENANT_PATHS} from 'shared/constants.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './ConfirmRefusalDecisionScreen.module.scss';

export default function ConfirmRefusalDecisionScreen() {
  const history = useHistory();
  const {search} = useLocation();
  const token = search.replace('?', '');

  return (
    <Layout>
      <div className={styles.container}>
        <Title label={_('Möchten Sie das Angebot wirklich ablehnen?')} />
        <p
          className={classNames(
            text_styles.body1_bright_centered,
            styles.case_yes,
          )}>
          {_(
            'Falls ja, können Sie die Wohnung nicht mehr mit dem Momo-Mietkautionsschutz beziehen und das Angebot in der E-Mail wird ungültig.',
          )}
        </p>
        <Button
          onClick={() => rejectInvitation({token, history})}
          secondary={true}
          className={styles.button}
          title={_('Ja, Angebot ablehnen')}
        />
        <p
          className={classNames(
            text_styles.body1_bright_centered,
            styles.case_no,
          )}>
          {_('Falls „Nein“ können Sie das Angebot in der E-Mail bestätigen.')}
        </p>
        <Button
          onClick={() => history.push(TENANT_PATHS.RefusalCancelSuccessScreen)}
          secondary={true}
          className={styles.button}
          title={_('Nein, ich überlege noch')}
        />
      </div>
    </Layout>
  );
}

async function rejectInvitation({token, history}) {
  let reject_feedback_token;

  try {
    ({reject_feedback_token} = await RPC('rejectInvitation', {token}));
  } catch (err) {
    const location =
      err.code === NOT_FOUND
        ? _('https://www.getmomo.de/der-link-ist-nicht-mehr-gultig/')
        : _('https://www.getmomo.de/fehler/');
    redirectExternal(location);
    return;
  }

  history.push(`${TENANT_PATHS.RejectFeedbackScreen}?${reject_feedback_token}`);
}
