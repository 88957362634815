import React from 'react';
import ReactDOM from 'react-dom';
import 'shared/fonts.css';
import 'shared/bootstrap.scss';
import './index.scss';
import {l10n} from 'shared/l10n.js';

import en from './assets/langs/en.json';
import App from './App.js';

l10n.loadJSON(en);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
