import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {l10n} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';
import {redirectExternal} from 'shared/utils.js';

import WelcomeScreen from './scenes/welcome/WelcomeScreen.js';
import BasicInformationScreen from './scenes/application/basicInformation/BasicInformationScreen.js';
import PropertyInfoScreen from './scenes/application/propertyInfo/PropertyInfoScreen.js';
import AccountScreen from './scenes/application/account/AccountScreen.js';
import CurrentAddressScreen from './scenes/application/currentAddress/CurrentAddressScreen.js';
import ApplicationReviewScreen from './scenes/application/applicationReview/ApplicationReviewScreen.js';
import CurrentIncomeScreen from './scenes/application/currentIncome/CurrentIncomeScreen.js';
import PersonalDetailsScreen from './scenes/application/personalDetails/PersonalDetailsScreen.js';
import SummaryScreen from './scenes/application/summary/SummaryScreen.js';
import SuccessScreen from './scenes/end/SuccessScreen.js';
import InvalidLinkScreen from './scenes/error/InvalidLinkScreen.js';
import DataTransmittedScreen from './scenes/error/DataTransmittedScreen.js';
import ResendLinkScreen from './scenes/resendLink/ResendLinkScreen.js';
import GenericErrorScreen from './scenes/error/GenericErrorScreen.js';
import ScrollToTop from './components/ScrollToTop.js';
import ConfirmRefusalDecisionScreen from './scenes/confirmRefusalDecision/ConfirmRefusalDecisionScreen.js';
import RefusalCancelSuccessScreen from './scenes/refusalCancelSuccess/RefusalCancelSuccessScreen.js';
import RejectFeedbackScreen from './scenes/rejectFeedback/RejectFeedbackScreen.js';
import ThanksScreen from './scenes/rejectFeedback/ThanksScreen.js';
import PreviousAddressScreen from './scenes/application/previousAddress/PreviousAddressScreen.js';
import SecondTenantScreen from './scenes/application/secondTenant/SecondTenantScreen.js';
import SecondTenantInfoScreen from './scenes/application/secondTenantInfo/SecondTenantInfoScreen.js';
import InterimSuccessScreen from './scenes/application/interimSuccess/InterimSuccessScreen.js';
import BasicInformationScreen2 from './scenes/application/basicInformation2/BasicInformationScreen2.js';
import CurrentAddressScreen2 from './scenes/application/currentAddress2/CurrentAddressScreen2.js';
import PreviousAddressScreen2 from './scenes/application/previousAddress2/PreviousAddressScreen2.js';
import ApplicationReviewScreen2 from './scenes/application/applicationReview2/ApplicationReviewScreen2.js';
import CurrentIncomeScreen2 from './scenes/application/currentIncome2/CurrentIncomeScreen2.js';
import PersonalDetailsScreen2 from './scenes/application/personalDetails2/PersonalDetailsScreen2.js';
import PropertyOwnerInfoScreen from './scenes/application/ownerInfo/PropertyOwnerInfoScreen.js';
import RentalContractScreen from './scenes/application/rentalContract/RentalContractScreen.js';
import DepositAmountScreen from './scenes/application/depositAmount/DepositAmountScreen.js';
import ManagerInfoScreen from './scenes/application/managerInfo/ManagerInfoScreen.js';
import TenantLedOnboardingScreen from './scenes/tenantLedOnboarding/TenantLedOnboardingScreen.js';

export default function Router() {
  const {language} = useSelector((state) => state.languageSelector);
  l10n.setLocale(language);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path={TENANT_PATHS.TenantLedOnboarding}>
          <TenantLedOnboardingScreen />
        </Route>
        {/* Query: ?token */}
        <Route path={TENANT_PATHS.WelcomeScreen}>
          <WelcomeScreen />
        </Route>
        <RedirectWithQuery
          path="/einladung-ablehnen"
          redirect_to={TENANT_PATHS.WelcomeScreen}
          state={{
            optout: true,
          }}
        />
        <Route exact path={TENANT_PATHS.BasicInformationScreen}>
          <BasicInformationScreen />
        </Route>
        <Route exact path={TENANT_PATHS.BasicInformationScreen2}>
          <BasicInformationScreen2 />
        </Route>
        <Route exact path={TENANT_PATHS.PropertyInfoScreen}>
          <PropertyInfoScreen />
        </Route>
        <Route exact path={TENANT_PATHS.PropertyOwnerInfoScreen}>
          <PropertyOwnerInfoScreen />
        </Route>
        <Route exact path={TENANT_PATHS.ManagerInfoScreen}>
          <ManagerInfoScreen />
        </Route>
        <Route path={TENANT_PATHS.RentalContractScreen}>
          <RentalContractScreen />
        </Route>
        <Route path={TENANT_PATHS.DepositAmountScreen}>
          <DepositAmountScreen />
        </Route>
        <Route path={TENANT_PATHS.AccountScreen}>
          <AccountScreen />
        </Route>
        <Route path={TENANT_PATHS.SecondTenantScreen}>
          <SecondTenantScreen />
        </Route>
        <Route path={TENANT_PATHS.SecondTenantInfoScreen}>
          <SecondTenantInfoScreen />
        </Route>
        <Route exact path={TENANT_PATHS.CurrentAddressScreen}>
          <CurrentAddressScreen />
        </Route>
        <Route exact path={TENANT_PATHS.CurrentAddressScreen2}>
          <CurrentAddressScreen2 />
        </Route>
        <Route exact path={TENANT_PATHS.PreviousAddressScreen}>
          <PreviousAddressScreen />
        </Route>
        <Route exact path={TENANT_PATHS.PreviousAddressScreen2}>
          <PreviousAddressScreen2 />
        </Route>
        <Route exact path={TENANT_PATHS.ApplicationReviewScreen}>
          <ApplicationReviewScreen />
        </Route>
        <Route exact path={TENANT_PATHS.ApplicationReviewScreen2}>
          <ApplicationReviewScreen2 />
        </Route>
        <Route exact path={TENANT_PATHS.CurrentIncomeScreen}>
          <CurrentIncomeScreen />
        </Route>
        <Route exact path={TENANT_PATHS.CurrentIncomeScreen2}>
          <CurrentIncomeScreen2 />
        </Route>
        <Route exact path={TENANT_PATHS.PersonalDetailsScreen}>
          <PersonalDetailsScreen />
        </Route>
        <Route exact path={TENANT_PATHS.PersonalDetailsScreen2}>
          <PersonalDetailsScreen2 />
        </Route>
        <Route path={TENANT_PATHS.InterimSuccessScreen}>
          <InterimSuccessScreen />
        </Route>
        <Route path={TENANT_PATHS.SummaryScreen}>
          <SummaryScreen />
        </Route>
        <Route path={TENANT_PATHS.SuccessScreen}>
          <SuccessScreen />
        </Route>
        {/* Query: ?token */}
        <Route path={TENANT_PATHS.RejectFeedbackScreen}>
          <RejectFeedbackScreen />
        </Route>
        <Route exact path={TENANT_PATHS.ThanksScreen}>
          <ThanksScreen />
        </Route>
        <Route path={TENANT_PATHS.ConfirmRefusalDecisionScreen}>
          <ConfirmRefusalDecisionScreen />
        </Route>
        <Route path={TENANT_PATHS.RefusalCancelSuccessScreen}>
          <RefusalCancelSuccessScreen />
        </Route>
        <Route path={TENANT_PATHS.ResendLinkScreen}>
          <ResendLinkScreen />
        </Route>
        {/* Errors */}
        <Route path={TENANT_PATHS.InvalidLinkScreen}>
          <InvalidLinkScreen />
        </Route>
        <Route path={TENANT_PATHS.DataTransmittedScreen}>
          <DataTransmittedScreen />
        </Route>
        <Route path={TENANT_PATHS.GenericErrorScreen}>
          <GenericErrorScreen />
        </Route>
        <Route exact path="*">
          {() => {
            redirectExternal('https://getmomo.de/mieter/');
            return null;
          }}
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function RedirectWithQuery({path, redirect_to, state}) {
  return (
    <Route
      path={path}
      render={(props) => {
        const {search} = props.location;
        return (
          <Redirect
            to={{
              pathname: redirect_to,
              search,
              state,
            }}
          />
        );
      }}
    />
  );
}
