import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {_} from 'shared/l10n.js';
import {STEP_NAME, TENANT_PATHS, VALIDATION_ERROR} from 'shared/constants.js';

import {
  alertLowIncome,
  alertLowIncomeRentRatio,
} from '../currentIncome/CurrentIncomeScreen.js';
import CurrentIncomeStep from '../../../components/steps/CurrentIncomeStep/CurrentIncomeStep.js';
import {
  getSecondTenantDisplayName,
  isDevFormEnabled,
} from '../../../lib/utils.js';

export default function CurrentIncomeScreen2() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;
  const {provider, cold_rent_cents, tenants} = contract_details;

  const goToNextStep = useCallback(
    ({fields, setError, setIsLoading}) => {
      proceed({
        token,
        fields,
        history,
        setError,
        setIsLoading,
        cold_rent_cents,
      });
    },
    [history, token, cold_rent_cents],
  );

  const saveAndGoBack = useCallback(
    async ({fields}) => {
      tenants[1] = {...tenants[1], ...fields};

      const {previous_step} = await RPC('getNavigationState', {
        path: TENANT_PATHS.CurrentIncomeScreen2,
        contract_details,
      });
      history.push(previous_step, {contract_details, token});
    },
    [contract_details, history, token, tenants],
  );

  return (
    <>
      <CurrentIncomeStep
        contract_details={{
          provider,
          ...tenants[1],
        }}
        goToNextStep={goToNextStep}
        saveAndGoBack={saveAndGoBack}
        bottom_navigation_label={_('Noch 2 Schritte')}
        tenant_display_name={getSecondTenantDisplayName(contract_details)}
        tenant_index={1}
      />
    </>
  );
}

async function proceed({
  token,
  fields,
  history,
  setError,
  setIsLoading,
  cold_rent_cents,
}) {
  setIsLoading(true);
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.current_income,
      ...fields,
      token,
      tenant_index: 1,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (
      err.code === BAD_REQUEST &&
      Array.isArray(err.data) &&
      err.data.length > 0
    ) {
      const {message: incomes_message} =
        err.data?.find?.(({path}) => path === '/incomes') || {};
      if (incomes_message === VALIDATION_ERROR.income_too_low_vvrb) {
        alertLowIncome();
        return;
      }
      if (
        incomes_message === VALIDATION_ERROR.income_less_than_2x_cold_rent_vvrb
      ) {
        alertLowIncomeRentRatio(cold_rent_cents);
        return;
      }
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
      return;
    }
    history.push(TENANT_PATHS.GenericErrorScreen);
    return;
  } finally {
    setIsLoading(false);
  }

  history.push(next_step, {contract_details, token});
}
