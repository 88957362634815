import React from 'react';

import Footer from '../components/Footer.js';

import Header from './header/Header.js';
import styles from './Layout.module.scss';

export default function Layout({
  children,
  display_customer_service_email = false,
  display_momo_website_link = true,
}) {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>{children}</div>
      <Footer
        display_customer_service_email={display_customer_service_email}
        display_momo_website_link={display_momo_website_link}
      />
    </div>
  );
}
