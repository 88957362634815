import React from 'react';
import classNames from 'classnames/bind';
import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './Error.module.scss';

export default function GenericErrorScreen() {
  return (
    <Layout>
      <Title label={_('Da ist etwas schiefgegangen, es tut uns leid')} />
      <p
        className={classNames(
          text_styles.body1_bright_centered,
          styles.margin_bottom_m,
        )}>
        {_(
          'Wir versuchen das Problem schnellstmöglich zu beheben. Bitte versuchen Sie es nach einem kurzen Moment erneut.',
        )}
      </p>
    </Layout>
  );
}
