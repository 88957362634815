import classNames from 'classnames';
import React from 'react';
import Button from 'shared/components/Button';
import text_styles from 'shared/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './ThanksScreen.module.scss';

export default function ThanksScreen() {
  return (
    <Layout>
      <Title label={_('Vielen Dank für Ihr Feedback')} />
      <p className={classNames(text_styles.body2_bright, styles.text)}>
        {_(
          'Sollten Sie doch Interesse am Momo-Kautionsschutz haben, stehen wir Ihnen jederzeit gerne zur Verfügung:',
        )}
      </p>

      <a
        href={`mailto:${_('kontakt@getmomo.de')}`}
        rel="noreferrer"
        target="_blank"
        className={styles.button_text}>
        <Button
          title={_('Momo kontaktieren')}
          secondary={true}
          className={styles.button}
        />
      </a>
    </Layout>
  );
}
