import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import TextArea from 'shared/components/TextArea';
import RadioButton from 'shared/components/RadioButton';
import {getRejectReasons} from 'shared/utils.js';
import {setFormErrors} from 'shared/effects';
import {_} from 'shared/l10n.js';
import {TENANT_PATHS} from 'shared/constants.js';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './RejectFeedbackScreen.module.scss';

export default function RejectFeedbackScreen() {
  const history = useHistory();
  const {search} = useLocation();
  const token = search.replace('?', '');
  const reject_reasons = getRejectReasons();

  const {
    handleSubmit,
    control,
    register,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) =>
    proceed({
      token,
      fields,
      history,
      setError,
    }),
  );

  return (
    <Layout display_customer_service_email={true}>
      <Title
        label={_(
          'Schade, dass Sie kein Interesse am Momo-Kautionsschutz haben',
        )}
      />
      <p className={text_styles.body2_bright}>
        {_(
          'Helfen Sie uns dabei, uns zu verbessern und wählen Sie den Grund Ihrer Absage:',
        )}
      </p>
      <form>
        <Controller
          control={control}
          name="reject_reason"
          render={({field: {value, onChange, name}}) => (
            <div className={styles.radio_group}>
              {Object.keys(reject_reasons).map((reason, idx) => {
                return (
                  <RadioButton
                    name={name}
                    checked={value === reason}
                    label={reject_reasons[reason]}
                    value={reason}
                    key={idx}
                    onChange={onChange}
                    isBright={true}
                  />
                );
              })}
              <p className={classNames(text_styles.caption_left, styles.error)}>
                {errors[name]?.message}
              </p>
            </div>
          )}
        />

        <TextArea
          placeholder={_('Hier können Sie eine eigene Antwort verfassen …')}
          rows="5"
          maxLength="500"
          isBright={true}
          error={errors.reject_comment?.message}
          {...register('reject_comment')}
        />

        <Button
          onClick={onSubmit}
          secondary={true}
          className={styles.submit_button}
          title={_('Feedback absenden')}
        />
      </form>
    </Layout>
  );
}

async function proceed({token, history, setError, fields}) {
  try {
    await RPC('submitRejectFeedback', {
      token,
      ...fields,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(TENANT_PATHS.ThanksScreen);
}
