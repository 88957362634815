import React from 'react';
import {useHistory} from 'react-router-dom';
import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import {RPC} from 'shared/api.js';
import {TENANT_PATHS} from 'shared/constants.js';

import BackButton from '../../../components/BackButton.js';

import styles from './InterimSuccessScreen.module.scss';
import hand from './gfx_thanks.png';

export default function InterimSuccessScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  async function onReturn() {
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.InterimSuccessScreen,
      contract_details,
    });

    history.push(previous_step, {
      contract_details,
      token,
    });
  }

  async function onContinue() {
    const {next_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.InterimSuccessScreen,
      contract_details,
    });

    history.push(next_step, {
      contract_details,
      token,
    });
  }

  return (
    <div className={styles.background}>
      <div className={styles.header}>
        <BackButton onReturn={onReturn} />
      </div>
      <div className={styles.container}>
        <img src={hand} alt="momo hand" height="215px" width="327px" />
        <span className={styles.text_background}>
          <p className={text_styles.h1_bold_centered}>{_('Vielen Dank')}</p>
        </span>

        <section className={styles.section}>
          <p className={text_styles.body1_centered}>
            {_('Wir sind fertig mit den Fragen für den ersten Mieter.')}
          </p>
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.bolded_text,
            )}>
            {_('Nun benötigen wir die Angaben von dem zweiten Mieter.')}
          </p>

          <Button
            onClick={onContinue}
            className={styles.continue_button}
            title={_('Weiter')}
          />
          <p className={text_styles.body1_centered}>
            {_('Bei Fragen erreichen Sie uns hier:')}
          </p>
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.title,
            )}>
            <a
              href={`mailto:${_('kundenservice@getmomo.de')}`}
              rel="noreferrer"
              target="_blank"
              className={classNames(
                text_styles.body1_bold_centered,
                styles.email,
              )}>
              {_('kundenservice@getmomo.de')}
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}
