import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {_} from 'shared/l10n.js';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {STEP_NAME, TENANT_PATHS} from 'shared/constants.js';
import Select from 'shared/components/Select.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';

import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import Input from '../../../components/Input.js';
import RadioGroup from '../../../components/RadioGroup.js';
import {getNationalityOptions, isDevFormEnabled} from '../../../lib/utils.js';
import FormHeader from '../../../components/FormHeader.js';
import SelectGender from '../../../components/SelectGender.js';

import styles from './PropertyOwnerInfoScreen.module.scss';
import CoownerFormSection from './CoownerFormSection.js';

export default function PropertyOwnerInfoScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({token, fields, history, setError});
  });

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.PropertyOwnerInfoScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset({
      ...contract_details,
      property_owner_has_coowner:
        contract_details?.property_owner_has_coowner || false,
    });
  }, [contract_details, reset]);

  const property_owner_type = watch('property_owner_type');
  const company_selected = property_owner_type === 'company';
  const individual_selected = property_owner_type === 'individual';

  return (
    <FormWrapper>
      <FormHeader
        title={_('Eigentümer')}
        subtitle={
          // TRANSLATORS: There is an invisible character in the german source string; it's a soft hyphen between "Bürgschafts" and "­urkunde"
          _(
            'Wir benötigen diese Angaben um die Bürgschafts­urkunde für Ihren Kautionsschutz zu erstellen.',
          )
        }
      />

      <div className={styles.row}>
        <Controller
          control={control}
          name="property_owner_type"
          render={({field: {onChange, value, name}}) => (
            <RadioGroup
              label={_(
                'Welche Rechtsform hat der Eigentümer der Mieteinheit? *',
              )}
              name={name}
              onChange={onChange}
              value={value}
              error={errors[name]?.message}
              option_1="company"
              option_1_label={_('Firma')}
              option_2="individual"
              option_2_label={_('Privatperson')}
            />
          )}
        />
      </div>

      {company_selected && (
        <CompanyForm
          contract_details={contract_details}
          errors={errors}
          register={register}
        />
      )}

      {individual_selected && (
        <IndividualForm
          contract_details={contract_details}
          errors={errors}
          register={register}
          watch={watch}
          setValue={setValue}
        />
      )}

      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />

      <p className={classNames(text_styles.caption_left, styles.helper_text)}>
        {_('* Diese Angabe finden Sie auf der Vorderseite des Mietvertrages')}
      </p>
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.property_owner_info,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else {
      history.push(TENANT_PATHS.GenericErrorScreen);
    }
    return;
  }

  history.push(next_step, {contract_details, token});
}

function CompanyForm({contract_details, errors, register}) {
  return (
    <>
      <Input
        defaultValue={contract_details?.property_owner_company_name || ''}
        placeholder={_('z. B. Schmitt Immobilien GmbH')}
        label={_('Name und Rechtsform *')}
        error={errors.property_owner_company_name?.message}
        {...register('property_owner_company_name')}
      />

      <div className={styles.row}>
        <Input
          defaultValue={contract_details?.property_owner_street_name || ''}
          label={_('Straße *')}
          error={errors.property_owner_street_name?.message}
          className={styles.property_owner_street_name}
          {...register('property_owner_street_name')}
        />
        <Input
          defaultValue={contract_details?.property_owner_street_number || ''}
          label={_('Hausnr. *')}
          error={errors.property_owner_street_number?.message}
          {...register('property_owner_street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={contract_details?.property_owner_postal_code || ''}
          label={_('PLZ *')}
          error={errors.property_owner_postal_code?.message}
          className={styles.property_owner_postal_code}
          {...register('property_owner_postal_code')}
        />
        <Input
          defaultValue={contract_details?.property_owner_region || ''}
          label={_('Ort *')}
          error={errors.property_owner_region?.message}
          className={styles.property_owner_region}
          {...register('property_owner_region')}
        />
      </div>

      <Select
        label={_('Land *')}
        options={getNationalityOptions()}
        defaultValue={contract_details?.property_owner_country || 'DE'}
        {...register('property_owner_country')}
      />
    </>
  );
}

function IndividualForm({contract_details, errors, register, watch, setValue}) {
  return (
    <>
      <Input
        defaultValue={contract_details?.property_owner_first_name || ''}
        label={_('Vorname des Eigentümers? *')}
        error={errors.property_owner_first_name?.message}
        {...register('property_owner_first_name')}
      />
      <Input
        defaultValue={contract_details?.property_owner_last_name || ''}
        label={_('Nachname des Eigentümers? *')}
        error={errors.property_owner_last_name?.message}
        {...register('property_owner_last_name')}
      />

      <SelectGender
        label={_('Geschlecht *')}
        className={styles.margin_bottom}
        error={errors.property_owner_gender?.message}
        {...register('property_owner_gender')}
      />

      <div className={styles.row}>
        <Input
          defaultValue={contract_details?.property_owner_street_name || ''}
          label={_('Straße *')}
          error={errors.property_owner_street_name?.message}
          className={styles.property_owner_street_name}
          {...register('property_owner_street_name')}
        />
        <Input
          defaultValue={contract_details?.property_owner_street_number || ''}
          label={_('Hausnr. *')}
          error={errors.property_owner_street_number?.message}
          {...register('property_owner_street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={contract_details?.property_owner_postal_code || ''}
          label={_('PLZ *')}
          error={errors.property_owner_postal_code?.message}
          className={styles.property_owner_postal_code}
          {...register('property_owner_postal_code')}
        />
        <Input
          defaultValue={contract_details?.property_owner_region || ''}
          label={_('Ort *')}
          error={errors.property_owner_region?.message}
          className={styles.property_owner_region}
          {...register('property_owner_region')}
        />
      </div>

      <div className={styles.row}>
        <Select
          label={_('Land *')}
          options={getNationalityOptions()}
          defaultValue={contract_details?.property_owner_country || 'DE'}
          {...register('property_owner_country')}
        />
        <div className={styles.gutter} />
      </div>

      <CoownerFormSection
        errors={errors}
        register={register}
        watch={watch}
        setValue={setValue}
      />
    </>
  );
}
