import classNames from 'classnames';
import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import styles from './InvitationStepper.module.scss';

export default function InvitationStepper({step = 1}) {
  return (
    <div className={styles.invitation_stepper_wrapper}>
      <div className={styles.invitation_stepper}>
        <div className={classNames(styles.step, step >= 1 && styles.active)}>
          <span className={classNames(text_styles.caption_left, styles.number)}>
            {_('1')}
          </span>
          <span className={text_styles.caption_left}>{_('Mieter 1')}</span>
        </div>
        <div className={styles.divider}></div>
        <div className={classNames(styles.step, step >= 2 && styles.active)}>
          <span className={classNames(text_styles.caption_left, styles.number)}>
            {_('2')}
          </span>
          <span className={text_styles.caption_left}>{_('Mieter 2')}</span>
        </div>
        <div className={styles.divider}></div>
        <div className={classNames(styles.step, step >= 3 && styles.active)}>
          <span className={classNames(text_styles.caption_left, styles.number)}>
            {_('3')}
          </span>
          <span className={text_styles.caption_left}>{_('Angebot')}</span>
        </div>
      </div>
    </div>
  );
}
