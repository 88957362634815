import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';

import Layout from '../../components/Layout.js';
import Title from '../../components/Title.js';

import styles from './RefusalCancelSuccessScreen.module.scss';

export default function RefusalCancelSuccessScreen() {
  return (
    <Layout>
      <div className={styles.container}>
        <Title label={_('Vielen Dank')} />
        <p className={text_styles.body1_bright_centered}>
          {_(
            'Sie können das Angebot in der E-Mail per Klick auf „Vertragsabschluss bestätigen“ annehmen.',
          )}
        </p>
      </div>
    </Layout>
  );
}
