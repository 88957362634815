import React from 'react';
import Button from 'shared/components/Button.js';
import {_} from 'shared/l10n.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {Modal as BootstrapModal} from 'react-bootstrap';

import {ReactComponent as CircleTickSvg} from './circle_tick.svg';
import {ReactComponent as CircleXSvg} from './circle_x.svg';
import styles from './AcceptedDocumentsModal.module.scss';

export default function AcceptedDocumentsModal({
  name,
  show,
  uploadFiles,
  onClose,
  dataTestId,
  ...props
}) {
  return (
    <BootstrapModal
      className={styles.modal_container}
      dialogClassName={styles.modal}
      backdrop="static"
      onHide={onClose}
      show={show}
      {...props}>
      <label
        htmlFor={name}
        className={styles.document}
        data-testid={dataTestId}
      />
      <DocumentInput name={name} uploadFiles={uploadFiles} onClose={onClose} />

      <div className={styles.content}>
        <div className={text_styles.body1_bold_centered}>
          {_(
            'Für Ihren Einkommensnachweis benötigen wir ein Foto von einer aktuellen Entgeltabrechnung',
          )}
        </div>

        <ListItem
          Icon={CircleTickSvg}
          text={_('Gehalts- oder Lohnabrechnung')}
          className={styles.first_list_item}
        />
        <ListItem Icon={CircleTickSvg} text={_('Bezügemitteilung')} />
        <ListItem Icon={CircleTickSvg} text={_('Besoldungsmitteilung')} />
        <ListItem Icon={CircleTickSvg} text={_('Rentennachweis')} />
        <ListItem Icon={CircleXSvg} text={_('Kontoauszüge')} />
        <ListItem Icon={CircleXSvg} text={_('ImmoScout Einkommensnachweis')} />
        <ListItem Icon={CircleXSvg} text={_('Elterngeldbescheid')} />

        <label
          htmlFor={name}
          className={styles.upload_button}
          data-testid={dataTestId}>
          <p className={text_styles.button_bold_bright}>
            {_('Foto hinzufügen')}
          </p>
        </label>
        <DocumentInput
          name={name}
          uploadFiles={uploadFiles}
          onClose={onClose}
        />

        <Button onClick={onClose} title={_('Abbrechen')} secondary />
      </div>
    </BootstrapModal>
  );
}

function ListItem({Icon, text, className}) {
  return (
    <div className={className || styles.list_item}>
      <Icon className={styles.icon} />
      <div className={text_styles.body2}>{text}</div>
    </div>
  );
}

function DocumentInput({name, uploadFiles, onClose}) {
  return (
    <input
      id={name}
      name={name}
      type="file"
      accept=".jpg, .jpeg, .png, .pdf"
      hidden
      multiple
      onClick={(e) => (e.target.value = null)}
      onChange={(e) => {
        uploadFiles([...e.target.files]);
        onClose();
      }}
    />
  );
}
