import text_styles from 'shared/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';
import Button from 'shared/components/Button.js';
import CircleSpinner from 'shared/components/CircleSpinner.js';

import styles from './BottomNavigation.module.scss';

export default function BottomNavigation({
  onSubmit,
  onBack,
  label,
  is_last_step = false,
  is_loading = false,
}) {
  if (is_loading) {
    return <Loading />;
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.buttons_wrapper}>
        <Button
          onClick={onSubmit}
          className={styles.continue_button}
          title={is_last_step ? _('Angebot anfordern') : _('Weiter')}></Button>
        {label && <p className={text_styles.typeface}>{label}</p>}
        {onBack && (
          <Button
            onClick={onBack}
            className={styles.return_button}
            title={_('←')}></Button>
        )}
      </div>
    </footer>
  );
}

function Loading() {
  return (
    <footer className={styles.footer}>
      <div className={styles.please_wait}>
        <div className={styles.spinner_wrapper}>
          <CircleSpinner
            className={styles.spinner}
            classNameOutline={styles.spinner_color}
            classNameSpinner={styles.spinner_color}
          />
        </div>
        <span className={text_styles.body1_bold_centered}>
          {_('Einen Moment bitte …')}
        </span>
      </div>
    </footer>
  );
}
