import {Controller, useForm} from 'react-hook-form';
import {useEffect, useCallback} from 'react';
import Select from 'shared/components/Select';
import {_} from 'shared/l10n.js';
import {RESIDENCE_RANGE} from 'shared/constants.js';
import SwitchToggle from 'shared/components/switchToggle/SwitchToggle.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames/bind.js';

import FormWrapper from '../../FormWrapper.js';
import BottomNavigation from '../../BottomNavigation.js';
import Input from '../../Input.js';
import {getNationalityOptions} from '../../../lib/utils.js';
import InvitationStepper from '../../InvitationStepper.js';
import FormHeader from '../../FormHeader.js';

import styles from './CurrentAddressStep.module.scss';

export default function CurrentAddressStep({
  contract_details,
  goToNextStep,
  saveAndGoBack,
  bottom_navigation_label,
  tenant_display_name,
  first_tenant_data,
  tenant_index = null,
}) {
  const {
    watch,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    register,
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const first_tenant_display_name = `${first_tenant_data?.first_name} ${first_tenant_data?.last_name}`;
  const onSubmit = handleSubmit((fields) => goToNextStep({fields, setError}));
  const onBack = useCallback(
    () => saveAndGoBack({fields: getValues()}),
    [saveAndGoBack, getValues],
  );

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  return (
    <FormWrapper tenant_display_name={tenant_display_name}>
      <FormHeader
        title={_('Aktuelle Adresse')}
        subtitle={_('Wie lautet die Adresse Ihres aktuellen Hauptwohnsitzes?')}
      />

      {tenant_index === 1 && (
        <Controller
          control={control}
          name="use_first_tenant_address"
          defaultValue={contract_details[1]?.use_first_tenant_address || false}
          render={({field: {value, onChange, name}}) => (
            <SwitchToggle
              label={_(
                'Möchten Sie die gleiche Adresse verwenden wie: %1',
                first_tenant_display_name,
              )}
              id={name}
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
              className={styles.switch_toggle}
            />
          )}
        />
      )}

      {watch('use_first_tenant_address') ? (
        <div className={styles.first_tenant_address}>
          <p className={classNames(text_styles.caption_left, styles.caption)}>
            {_('Adresse:')}
          </p>
          <p className={text_styles.body2}>
            {_(
              '%1 %2',
              first_tenant_data?.current_street_name,
              first_tenant_data?.current_street_number,
            )}
          </p>
          <p className={text_styles.body2}>
            {_(
              '%1 %2',
              first_tenant_data?.current_postal_code,
              first_tenant_data?.current_region,
            )}
          </p>
          <p className={text_styles.body2}>
            {
              getNationalityOptions().find(
                (i) => i.id === first_tenant_data?.current_country,
              ).name
            }
          </p>
        </div>
      ) : (
        <>
          <div className={styles.row}>
            <Input
              label={_('Straße')}
              error={errors.current_street_name?.message}
              className={styles.grow_3}
              {...register('current_street_name')}
            />
            <Input
              label={_('Hausnummer')}
              error={errors.current_street_number?.message}
              className={styles.grow_1}
              {...register('current_street_number')}
            />
          </div>
          <div className={styles.row}>
            <Input
              label={_('PLZ')}
              error={errors.current_postal_code?.message}
              className={styles.grow_1}
              {...register('current_postal_code')}
            />
            <Input
              label={_('Ort')}
              error={errors.current_region?.message}
              className={styles.grow_3}
              {...register('current_region')}
            />
          </div>
          <Select
            label={_('Land')}
            defaultValue="DE"
            options={getNationalityOptions()}
            className={styles.margin_bottom}
            error={errors.current_country?.message}
            {...register('current_country')}
          />
        </>
      )}

      <Select
        label={_('Wie lange wohnen Sie bereits in dieser Wohnung?')}
        options={getResidencySinceOptions()}
        className={styles.margin_bottom}
        error={errors.current_address_range?.message}
        {...register('current_address_range')}
      />

      <BottomNavigation
        onSubmit={onSubmit}
        onBack={onBack}
        label={bottom_navigation_label}
      />

      {tenant_index !== null && <InvitationStepper step={tenant_index + 1} />}
    </FormWrapper>
  );
}

function getResidencySinceOptions() {
  return [
    {id: RESIDENCE_RANGE.less_than_or_equal_3, name: _('Weniger als 3 Jahre')},
    {id: RESIDENCE_RANGE.less_than_or_equal_5, name: _('3 bis 5 Jahre')},
    {id: RESIDENCE_RANGE.less_than_or_equal_10, name: _('5 bis 10 Jahre')},
    {id: RESIDENCE_RANGE.more_than_10, name: _('Mehr als 10 Jahre')},
  ];
}
