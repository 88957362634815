import {Controller} from 'react-hook-form';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import {_} from 'shared/l10n.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import Button from 'shared/components/Button.js';

import {overlayConfirm} from '../../../effects.js';
import FormHeader from '../../FormHeader.js';

import precontractual_information_pdf from './Vorvertragliche-Informationen.pdf';
import schufa_pdf from './SCHUFA.pdf';
import privacy_notice_pdf from './Datenschutzhinweise.pdf';
import terms_of_service_pdf from './Allgemeine Geschäftsbedingungen.pdf';
import styles from './ApplicationReviewStep.module.scss';

export default function ConsentVvrb({
  control,
  errors,
  setValue,
  user_consents,
}) {
  return (
    <>
      <FormHeader
        title={_('Antragsprüfung')}
        subtitle={_(
          'Um Ihnen den bestmöglichen Service zu bieten, arbeiten wir mit Partnerbanken zusammen. Hierfür benötigen wir folgende Bestätigungen von Ihnen.',
        )}
      />
      <Button
        className={styles.select_all}
        title={_('Alle auswählen')}
        onClick={() => {
          setValue('agreed_to_vvrb_information_request', true);
          setValue('agreed_to_vvrb_economic_interest', true);
          setValue('agreed_to_vvrb_explanations_desired', true);
          setValue('agreed_to_vvrb_contract_discussion', true);
          setValue('agreed_to_vvrb_consent_data_transmission', true);
          setValue('agreed_to_vvrb_consent_data_processing', true);
        }}
      />
      <div className={styles.confirm}>
        <Controller
          control={control}
          name="agreed_to_vvrb_information_request"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <>
                  <p>
                    {user_consents.tenant_vvrb_information_request.description}
                  </p>
                  <OverlayButton
                    name={name}
                    title={_('Auskunftsanfrage')}
                    text={_(
                      'Die Bank darf beim Grundbuchamt, Handelsregister, Güterrechtsregister oder Einwohnermeldeamt Auskünfte einholen, sich Unterlagen – insbesondere Abschriften aus öffentlichen Registern – beschaffen und dort Einsicht in die Register und Akten nehmen.',
                    )}
                    setValue={setValue}
                  />
                </>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="agreed_to_vvrb_economic_interest"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <>
                  <p>
                    {user_consents.tenant_vvrb_economic_interest.description}
                  </p>
                  <OverlayButton
                    name={name}
                    title={_('Eigene Rechnung')}
                    text={_(
                      'Sie beantragen das Produkt für sich selber, die Aufnahme erfolgt auf eigene Rechnung.',
                    )}
                    setValue={setValue}
                  />
                </>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="agreed_to_vvrb_explanations_desired"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <>
                  <p>
                    {user_consents.tenant_vvrb_explanations_desired.description}
                  </p>
                  <OverlayButton
                    name={name}
                    title={_('Vorvertragliche Vertragsinformationen')}
                    text={_(
                      'Der Darlehensnehmer wünscht keine weiteren Erläuterungen, weil er auf Nachfrage die vorvertraglichen Informationen verstanden hat. Sofern der Haken entfernt wird, ist eine Beratung durch den Berater der Volksbank erforderlich.\n\nSie können sich die Informationen herunterladen:',
                    )}
                    link={
                      <Link
                        href={precontractual_information_pdf}
                        text={_('Vorvertragliche-Informationen.pdf')}
                      />
                    }
                    setValue={setValue}
                  />
                </>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="agreed_to_vvrb_contract_discussion"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <>
                  <p>
                    {user_consents.tenant_vvrb_contract_discussion.description}
                  </p>
                  <OverlayButton
                    name={name}
                    title={_('Die Vertragsinhalte wurden erörtert')}
                    text={_(
                      'Die Vertragsparteien bzw. deren Vertreter haben den wesentlichen Inhalt des Vertrages vor oder bei Abschluss erörtert. Dies ist zwingend erforderlich.',
                    )}
                    setValue={setValue}
                  />
                </>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="agreed_to_vvrb_consent_data_transmission"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <>
                  <p>
                    {
                      user_consents.tenant_vvrb_consent_data_transmission
                        .description
                    }
                  </p>
                  <OverlayButton
                    name={name}
                    title={_('Vorvertragliche Vertragsinformationen')}
                    text={_(
                      'Datenübermittlung an die SCHUFA und Befreiung vom Bankgeheimnis:\n\nDie Vereinigte Volksbank Raiffeisenbank eG übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f der Datenschutz-Grundverordnung (DS-GVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DS-GVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank/Sparkasse oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes). Der Kunde befreit die Vereinigte Volksbank Raiffeisenbank eG insoweit auch vom Bankgeheimnis. Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DS-GVO entnommen oder online unter www.schufa.de/datenschutz eingesehen werden.\n\nSie können sich die Informationen herunterladen:',
                    )}
                    link={
                      <Link
                        href={schufa_pdf}
                        text={_('Schufa-Informationen.pdf')}
                      />
                    }
                    setValue={setValue}
                  />
                </>
              }
            />
          )}
        />
        <Controller
          control={control}
          name="agreed_to_vvrb_consent_data_processing"
          defaultValue={false}
          render={({field: {value, onChange, name}}) => (
            <Checkbox
              id={name}
              name={name}
              textStyle={classNames(text_styles.body2_left, styles.checkbox)}
              className={styles.checkbox_container}
              error={errors[name]?.message}
              value={value}
              onChange={onChange}
              label={
                <p>
                  {
                    user_consents.tenant_vvrb_consent_data_processing
                      .description
                  }{' '}
                  <Link href={terms_of_service_pdf} text={_('AGB')} />{' '}
                  <Link href={privacy_notice_pdf} text={_('Datenschutzb.')} />
                </p>
              }
            />
          )}
        />
      </div>
    </>
  );
}

function OverlayButton({name, title, text, link, setValue}) {
  return (
    <button
      className={classNames(text_styles.body2_left, styles.overlay_button)}
      onClick={async () => {
        const result = await overlayConfirm({
          name,
          title,
          text,
          link,
        });
        setValue(name, result);
      }}>
      {'('}
      {_('Weitere Informationen')}
      {')'}
    </button>
  );
}

function Link({href, text}) {
  return (
    <a
      className={classNames(text_styles.body2_left, styles.checkbox)}
      target="_blank"
      rel="noreferrer"
      href={href}>
      {'('}
      {text}
      {')'}
    </a>
  );
}
